import * as CryptoJS from 'crypto-js'

const CryptService = (function() {
  var me = {}

  var _sep = ''
  var _slt = ''

  me.setSlt = function(slt) {
    localStorage.setItem('slt', slt)
    _slt = slt
  }

  me.getSlt = function() {
    _slt = _slt || localStorage.getItem('slt')
    if(!_slt) {
      console.error('Salt is not defined!')
    }
    return _slt
  }

  me.setSym = function(sym) {
    _sep = sym
  }

  me.getKey = function() {
    return _key
  }

  me.crypt = function(data) {
    console.log('frontend crypt', data, _slt)
    if (typeof data === 'object') {
      data = JSON.stringify(data)
    }
    return CryptoJS.AES.encrypt(data, _slt)
      .toString()
      .split('/')
      .join(_sep)
  }

  me.decrypt = function(id, json = false) {
    const dec = CryptoJS.AES.decrypt(id.split(_sep).join('/'), _slt).toString(CryptoJS.enc.Utf8)
    if (json) {
      return JSON.parse(dec)
    }
    return dec
  }

  me.sha1 = function(v) {
    return CryptoJS.SHA1(v).toString()
  }

  return me
})()

export default CryptService
