import { authenticationGuard } from "@/auth/authGuard";
import { createRouter, createWebHistory } from "vue-router";
import { privateAccess } from "./auth/authGuard";
import AppLayout from "./layouts/AppLayout.vue";

const routes = [
  {
    path: "/callback",
    name: "callback",
    component: () => import("./layouts/Callback.vue"),
  },
  {
    path: "/",
    name: "app-layout",
    component: AppLayout,
    beforeEnter: authenticationGuard,
    beforeRouteUpdate (to, from, next)  {
      console.log(`beforeRouteUpdate`,  to, from, next)
      next();
    },
    children: [
      {
        path: "/",
        redirect: "/home",
      },
      {
        path: "/home",
        name: "home",
        component: () => import("./pages/HomePage.vue"),
        meta: {
          title: "Home",
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("./pages/ProfilePage.vue"),
        meta: {
          title: "My Profile",
        },
      },
      {
        path: "/stories/:id?",
        name: "stories",
        component: () => import("./pages/StoryPage.vue"),
        meta: {
          title: "Stories",
        },
      },
      {
        path: "/whats-new",
        name: "whats-new",
        component: () => import("./pages/WhatsNew.vue"),
        meta: {
          title: `What's New?`,
        },
      },
      {
        path: "/reports/:mode?",
        name: "reports",
        component: () => import("./pages/Reports.vue"),
        meta: {
          title: "Reports",
        },
      },
      {
        path: "/product",
        name: "insights",
        component: () => import("./pages/Insights.vue"),
        meta: {
          title: "Insights",
        },
      },
      {
        path: "product/:app/:tab?",
        name: "product",
        component: () => import("./pages/product/Product.vue"),
        meta: {
          title: "product-param",
        },
      },
      {
        path: "about-page/:key",
        name: "about-page",
        component: () => import("./pages/AboutPreview.vue"),
        meta: {
          title: "About Page Preview",
        },
      },
      {
        path: "/datafeeds/:app?/:section?",
        name: "datafeeds",
        component: () => import("./pages/DataFeeds.vue"),
        meta: {
          title: "Data Feeds",
        },
      },
      {
        path: "/catalog/:type",
        name: "tAndC",
        component: () => import("./pages/TAndC.vue"),
        meta: {
          title: "Terms and Conditions",
        },
      },
      {
        path: "/help",
        name: "help",
        component: () => import("./pages/Help.vue"),
        meta: {
          title: "Help",
        },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("./pages/Support.vue"),
        meta: {
          title: "Insights Hub Support",
        },
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("./pages/Feedback.vue"),
        meta: {
          title: "Insights Hub Feedback",
        },
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/admin/overview",
    component: () => import("./layouts/IhAdminLayout.vue"),
    beforeEnter: (to, from, next) => {
      // return next();
      try {
        return privateAccess(to, from, next, "ih");
      } catch (e) {
        // console.log("beforeEnter", e);
        next("/home");
      }
    },
    children: [
      {
        path: "/admin/overview",
        name: "ih-admin-overview",
        component: () => import("./pages/ih-admin/AdminOverview.vue"),
      },
      {
        path: "/admin/products",
        name: "ih-admin-products",
        component: () => import("./pages/ih-admin/AdminProducts.vue"),
        meta: {
          lvl: ["pdf_docs", "apps", "datafeed", "about", "stories", "request_form"],
        },
        children: [
          {
            path: "pdf-reports",
            name: "ih-admin-pdf-reports",
            component: () =>
              import(
                "./pages/ih-admin/components/products/admin-pdf-doc/AdminPdfDoc.vue"
              ),
            meta: {
              lvl: ["pdf_docs"],
            },
          },
          {
            path: "apps",
            name: "ih-admin-apps",
            component: () =>
              import("./pages/ih-admin/components/products/AdminApps.vue"),
            meta: {
              lvl: ["apps"],
            },
          },
          {
            path: "request-form",
            name: "ih-admin-req-form",
            component: () =>
              import("./pages/ih-admin/components/products/AdminRequestForm.vue"),
            meta: {
              lvl: ["request-form"],
            },
          },
          {
            path: "datafeed",
            name: "ih-admin-datafeed",
            component: () =>
              import("./pages/ih-admin/components/products/AdminDataFeed.vue"),
            meta: {
              lvl: ["datafeed"],
            },
          },
          {
            path: "about-pages",
            name: "ih-admin-about-pages",
            component: () =>
              import(
                "./pages/ih-admin/components/products/AdminAboutPages.vue"
              ),
            meta: {
              lvl: ["about"],
            },
          },
          {
            path: "stories",
            name: "ih-admin-stories",
            component: () =>
              import("./pages/ih-admin/components/products/AdminStories.vue"),
            meta: {
              lvl: ["stories"],
            },
          },
        ],
      },
      {
        path: "/admin/cms",
        name: "ih-admin-cms",
        component: () => import("./pages/ih-admin/AdminCms.vue"),
        meta: {
          lvl: ["help_page", "public_products", "login_cms"],
        },
        children: [
          {
            path: "public-products",
            name: "ih-admin-public-products",
            component: () =>
              import(
                "./pages/ih-admin/components/products/AdminPublicProducts.vue"
              ),
            meta: {
              lvl: ["public_products"],
            },
          },
          {
            path: "help-page",
            name: "ih-admin-help-page",
            component: () =>
              import(
                "./pages/ih-admin/components/products/AdminHelpPageCMS.vue"
              ),
            meta: {
              lvl: ["help_page"],
            },
          },
          {
            path: "login-cms",
            name: "ih-admin-login-cms",
            component: () =>
              import("./pages/ih-admin/components/products/AdminLoginCMS.vue"),
            meta: {
              lvl: ["login_cms"],
            },
          },
        ],
      },
      {
        path: "/admin/reference-data",
        name: "ih-admin-reference-data",
        component: () => import("./pages/ih-admin/AdminReferenceData.vue"),
        meta: {
          lvl: [
            "datafeed_groups",
            "classifications",
            "app_tags",
            "security_names",
            "company_types",
            "pdf_report_types",
            "team",
            "class_of_business",
            "report_category",
            "report_period",
          ],
        },
      },
      {
        path: "/admin/settings",
        name: "ih-admin-settings",
        component: () => import("./pages/ih-admin/AdminSettings.vue"),
        meta: {
          lvl: ["settings"],
        },
      },
      {
        path: "/admin/file-manager",
        name: "ih-admin-file-manager",
        component: () => import("./pages/ih-admin/AdminFileManager.vue"),
        meta: {
          lvl: ["file_manager"],
        },
      },
      {
        path: "/admin/notifications",
        component: () =>
          import("./pages/ih-admin/AdminNotificationsSection.vue"),
        meta: {
          lvl: ["subscriptions", "notifications", "news"],
        },
        children: [
          {
            path: "",
            name: "ih-admin-subscriptions",
            component: () =>
              import(
                "./pages/ih-admin/components/notifications/AdminSubscriptions.vue"
              ),
            meta: {
              lvl: ["subscriptions"],
            },
          },
          {
            path: "notifications",
            name: "ih-admin-notification",
            component: () =>
              import(
                "./pages/ih-admin/components/notifications/AdminNotifications.vue"
              ),
            meta: {
              lvl: ["notifications"],
            },
          },
          {
            path: "news",
            name: "ih-admin-news",
            component: () =>
              import("./pages/ih-admin/components/notifications/AdminNews.vue"),
            meta: {
              lvl: ["news"],
            },
          },
        ],
      },
      {
        path: "/admin/access",
        name: "ih-admin-access",
        component: () => import("./pages/ih-admin/AdminAccess.vue"),
        meta: {
          lvl: [
            "companies",
            "users",
            "domains",
            "administrators",
            "access_requests",
            "integration",
            "devolved_admin_requests",
            "user_attr_requests",
          ],
        },
      },
      {
        path: "/admin/feedback",
        name: "ih-admin-feedback",
        component: () => import("./pages/ih-admin/AdminFeedback.vue"),
        meta: {
          lvl: ["feedback"],
        },
      },
      {
        path: "/admin/db-tool",
        name: "ih-admin-db_tool",
        component: () => import("./pages/ih-admin/AdminDbTool.vue"),
        meta: {
          lvl: ["db_tool"],
        },
      },
      {
        path: "/admin/onboarding",
        name: "ih-admin-onboarding",
        component: () => import("./pages/ih-admin/AdminOnboarding.vue"),
        meta: {
          lvl: ["onboarding"],
        },
      },
    ],
  },
  {
    path: "/manage",
    redirect: "/manage/overview",
    component: () => import("./layouts/DelegatedAdminLayout.vue"),
    beforeEnter: (from, to, next) => {
      return privateAccess(to, from, next, "da");
    },
    children: [
      {
        path: "/manage/overview",
        name: "delegated-admin-overview",
        component: () =>
          import("./pages/delegated-admin/DelegatedOverview.vue"),
      },
      {
        path: "/manage/users",
        name: "delegated-admin-users",
        component: () => import("./pages/delegated-admin/DelegatedUsers.vue"),
      },
      {
        path: "/manage/company",
        name: "delegated-admin-company",
        component: () => import("./pages/delegated-admin/DelegatedCompany.vue"),
      },
      {
        path: "/manage/access-requests",
        name: "delegated-access-requests",
        component: () =>
          import("./pages/delegated-admin/DelegatedAccessRequests.vue"),
      },
      {
        path: "/manage/user-attr-requests",
        name: "user-attr-requests",
        component: () =>
          import("./pages/delegated-admin/DelegatedUserAttrRequests.vue"),
      },
    ],
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("./pages/landing/landing.vue"),
  },
  {
    path: "/request-report-form/:id?",
    name: "request-report-form",
    component: () => import("./pages/request-report-form/request-report-form.vue"),
  },
  {
    path: "/refresh",
    name: "refresh",
    component: () => import("./layouts/Refresh.vue"),
    beforeEnter: (from, to, next) => {
      next();
    },
  },
  {
    path: "/timeout",
    name: "timeout",
    component: () => import("./layouts/Timeout.vue"),
    meta: {
      title: "Session Expired!",
    },
  },
  {
    path: "/access-denied/:mode?",
    name: "access-denied",
    component: () => import("./layouts/AccessDenied.vue"),
    meta: {
      title: "Access Denied!",
    },
  },
  {
    path: "/choose-account/:mode?",
    name: "choose-account",
    component: () => import("./layouts/ChooseAccount.vue"),
    meta: {
      title: "Choose Account!",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./layouts/Logout.vue"),
    meta: {
      title: "Session Expired!",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./layouts/Login.vue"),
    meta: {
      title: "Auto Login",
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("./layouts/Login.vue"),
    meta: {
      title: "Auto Login",
    },
  },
  {
    path: "/approve-decline/:key",
    name: "approve-decline",
    component: () => import("./pages/ApproveDecline.vue"),
  },
  {
    path: "/company-changes-approve-decline/:key",
    name: "company-changes-approve-decline",
    component: () => import("./pages/CompanyChangesApproveDecline.vue"),
  },
  {
    path: "/user-attributes-approve-decline/:key",
    name: "user-attributes-approve-decline",
    component: () => import("./pages/UserAttributesApproveDecline.vue"),
  },
  {
    path: "/confirm-email/:key",
    name: "confirm-email",
    component: () => import("./pages/ConfirmEmail.vue"),
  },
  {
    path: "/unsubscribe/:key",
    name: "unsubscribe",
    component: () => import("./pages/UnsubscribeEmail.vue"),
  },
  {
    path: "/manage-subscriptions/:key",
    name: "manage-subscriptions",
    component: () => import("./pages/ManageSubscriptions.vue"),
  },
  {
    path: "/onboarding/:type?",
    name: "onboarding",
    component: () => import("./pages/onboarding/onboarding.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("./pages/404.vue"),
    meta: {
      title: "404 - Page not found",
    },
  },
];

function handleIalErrors() {
  var pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;
  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }
  if (obj.error) {
    console.log(obj);
    localStorage.setItem("ial_error", JSON.stringify(obj));
    alert(`Authentication failed: \n${obj.error_description}`);
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
