<template>
  <div
    v-if="item"
    class="vsm-item mobile-item"
    :class="[{ 'open-item': item.child }, { 'active-item': active }, { 'parent-active-item': childActive }]"
  >
    <template v-if="isRouterLink">
      <router-link
        class="vsm-link"
        :to="item.href"
        :disabled="item.disabled"
        :event="item.disabled ? '' : 'click'"
        @click.native="clickEvent($event, true)"
      >
        <i v-if="item.icon" class="vsm-icon material-icons">{{ item.icon }}</i>
        <span class="relative-ct">{{ item.title }}<span v-if="item.badge && +item.badge > 0" class="nr-badge">{{ item.badge }}</span></span>
        <i v-if="item.child" class="vsm-arrow open-arrow" />
      </router-link>
    </template>
    <template v-else>
      <a
        class="vsm-link"
        :href="item.href ? item.href : '#'"
        :disabled="item.disabled"
        @click="clickEvent($event, true)"
      >
        <i v-if="item.icon" class="vsm-icon material-icons">{{ item.icon }}</i>
        {{ item.title }}
        <i v-if="item.child" class="vsm-arrow open-arrow" />
      </a>
    </template>
  </div>
</template>

<script>
import { itemMixin } from './mixin'

export default {
  mixins: [itemMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  watch: {
    item() {
      this.active = this.item && this.item.href ? this.isLinkActive(this.item) : false
      this.childActive = this.item && this.item.child ? this.isChildActive(this.item.child) : false
    },
  },
}
</script>
<style scoped>
.nr-badge {
  background-color: rgb(10, 75, 183);
  font-weight: 600;
  color: white;
  border-radius: 50px;
  padding: 5px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -20px;
}
.relative-ct {
  position: relative;
}
</style>