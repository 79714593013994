<template>
  <div
    class="vsm-item"
    :class="[
      { 'first-item': firstItem },
      { 'open-item': show },
      { 'active-item': active },
      { 'parent-active-item': childActive },
    ]"
    @mouseenter="mouseEnter($event)"
  >
    <template v-if="isRouterLink">
      <router-link
        class="vsm-link relative-ct"
        :class="item.class"
        :to="item.href"
        :disabled="item.disabled"
        :event="item.disabled ? '' : 'click'"
        v-bind="item.attributes"
        @click.native="clickEvent"
        :target="item.target || '_self'"
      >
        <i v-if="item.icon" class="vsm-icon material-icons">{{ item.icon }}</i>
        <span v-if="+item.badge > 0 && isCollapsed" class="nr-badge">{{ item.badge }}</span>
        <template v-if="!isCollapsed">
          <span class="vsm-title"
            ><span class="relative-ct"
              >{{ item.title
              }}<span v-if="+item.badge > 0" class="nr-badge no-collapsed">{{ item.badge }}</span></span
            ></span
          >
          <template v-if="item.child">
            <i v-if="show" class="vsm-arrow material-icons">keyboard_arrow_up</i>
            AAA
          </template>
        </template>
      </router-link>
    </template>
    <template v-else>
      <a
        class="vsm-link"
        :class="item.class"
        :href="item.href ? item.href : '#'"
        :disabled="item.disabled"
        v-bind="item.attributes"
        @click="clickEvent"
        :target="item.target || '_self'"
      >
        <i v-if="item.icon" class="vsm-icon material-icons">{{ item.icon }}</i>
        <template v-if="!isCollapsed">
          <span class="vsm-title"
            ><span class="relative-ct"
              >{{ item.title
              }}<span v-if="+item.badge > 0" class="nr-badge no-collapsed">{{ item.badge }}</span></span
            ></span
          >

          <template v-if="item.child">
            <i v-if="show" class="vsm-arrow material-icons">keyboard_arrow_up</i>
            <i v-if="!show" class="vsm-arrow material-icons">keyboard_arrow_down</i>
          </template>
        </template>
      </a>
    </template>
    <template v-if="item.child">
      <template v-if="!isCollapsed">
        <transition
          name="expand"
          @enter="expandEnter"
          @afterEnter="expandAfterEnter"
          @beforeLeave="expandBeforeLeave"
        >
          <div v-if="show" class="vsm-dropdown">
            <div class="vsm-list">
              <sub-item v-for="(subItem, index) in item.child" :key="index" :item="subItem" />
            </div>
          </div>
        </transition>
      </template>
    </template>
  </div>
</template>

<script>
import SubItem from './SubItem.vue'
import { itemMixin, animationMixin } from './mixin'

export default {
  components: {
    SubItem,
  },
  mixins: [itemMixin, animationMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
    },
  },
  methods: {
    mouseEnter(event) {
      if (this.isCollapsed && this.firstItem) {
        this.$emit('mouseEnterItem', {
          item: this.item,
          pos: event.currentTarget.getBoundingClientRect().top - this.$parent.$el.getBoundingClientRect().top,
          height: this.$el.offsetHeight,
        })
      }
    },
  },
}
</script>
<style scoped>
.nr-badge {
  background-color: rgb(10, 75, 183);
  font-weight: 600;
  color: white;
  border-radius: 50px;
  padding: 5px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: -10px;
}
.relative-ct {
  position: relative;
}
.no-collapsed {
  top: -10px !important;
  right: -20px !important;
}
</style>
