<template>
  <BaseDialog ref="popup">
    <div class="content-ct">
      <p class="subtitle" v-html="message"></p>
    </div>
    <div class="form-row mb-0 mt-20">
      <div class="form-field" v-if="!withCancelBtn"></div>
      <button
        style="background: white !important;
        color: black !important;"
        class="mb-0"
        @click="_cancel(false)"
        v-if="withCancelBtn"
      >
        {{ cancelBtnTest }}
      </button>
      <button class="mb-0" @click="_confirm(true)">{{ okBtnText }}</button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '../BaseDialog.vue'

export default {
  name: 'InfoDialog',

  components: { BaseDialog },

  data: () => ({
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    message: '',
    okBtnText: 'Ok',
    cancelBtnTest: 'Cancel',
    withCancelBtn: false,
  }),

  methods: {
    show(opts = {}) {
      if (opts.okBtnText) {
        this.okBtnText = opts.okBtnText
      }
      if (opts.cancelBtnTest) {
        this.cancelBtnTest = opts.cancelBtnTest
      }
      this.withCancelBtn = opts.withCancelBtn
      this.message = opts.message
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped src="./register-interest/register-interest.css"></style>
<style scoped>

.text-ct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
}
.subtitle {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-20 {
  margin-top: 20px;
}
.f-start {
  align-items: flex-start !important;
}
.field-length {
  width: 325px !important;
}
.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date-field {
  width: 180px !important;
  margin-right: 8px !important;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0 !important;
  width: 100%;
}
.action-btn {
  padding: 0.5em 1em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.confirm {
  background-color: #282f54;
  color: #fff !important;
}
.content-ct {
  width: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 500px;
}
</style>
