import { getInstance } from "./authWrapper";
import HttpService from "@/services/http.service";
import AuthService from "@/services/auth.service";
import CryptService from "../services/crypt.service";
import {
  isAuthenticated,
  isLoading,
  getAccessToken,
} from "@/auth/auth0-plugin";
import { watch } from "vue";

/**
 * check auth0 access
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 * @param {*} role
 * @returns
 */
export const privateAccess = (to, from, next, role) => {
  try {
    const fn = async () => {
      const as = new AuthService();
      if (isAuthenticated.value) {
        const accessToken = await getAccessToken();
        HttpService.setToken(accessToken);
        const decode = as.decodeHeader();
        if(!decode) {
          await as.doLogout()
          return
        }
        if (role === "ih" && decode.is_ih_admin) {
          return next();
        }
        if (role === "da" && decode.is_da_admin) {
          // remove this to enable da access
          return next();
        }
        if (role === "reports" && decode.with_pdf_reports) {
          return next();
        }
        sessionStorage.setItem("logout_1", "from privateAccess function");
        sessionStorage.removeItem("view_mode");
        await as.doLogout()
        alert(`No permission found for this area!`);
        next("/home");
      }
      if (role === "ih" || role === "da") {
        // remove this to enable da access
        return next("/landing");
      }
      try {
        const { sym, key } = window.hubCfg;
        CryptService.setSym(sym);
        const cfg = CryptService.decrypt(key, true);
        window.cfg = cfg;
        window.full_cfg = cfg;
        localStorage.removeItem(cfg.crypt.header_token);
        sessionStorage.setItem("login_1", "from privateAccess function");
        // Otherwise, log in
        loginWithRedirect({ appState: { targetUrl: to.fullPath } });
      } catch (e) {
        // console.log("privateAccess", e);
        sessionStorage.setItem("logout_1_1", "from privateAccess function");
        sessionStorage.removeItem("view_mode");
        sessionStorage.removeItem("access_token");
        as.doLogout();
      }
    };
    // If loading has already finished, check our auth state using `fn()`
    if (!isLoading.value) {
      return fn();
    }
    // Watch for the loading property to change before we check isAuthenticated
    watch(
      () => isLoading.value,
      async (loading, old) => {
        // console.log("watch: ", loading, old);
        if (loading === false) {
          return fn();
        }
      }
    );
  } catch (e) {
    console.log("to timeout", e);
    next("/timeout");
  }
};

export const authenticationGuard = (to, from, next) => {
  const guardAction = async () => {
    if (isAuthenticated.value) {
      if(window.location.pathname === '/callback') {
        const as = new AuthService()
        await as.doLogin()
      }
      return next();
    } else {
      sessionStorage.setItem("returnUrl", to.fullPath);
      return next("/landing");
    }
    // const from = sessionStorage.getItem("from_logout") === true;
    // if (from) {
    // }
    // loginWithRedirect({
    //   appState: {
    //     targetUrl: to.fullPath,
    //   },
    // });
  };

  if (!isLoading.value) {
    return guardAction();
  }

  watch(isLoading, (currentValue) => {
    if (!currentValue) {
      return guardAction();
    }
  });

  // watch()
};
