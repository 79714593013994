<template>
  <BaseDialog ref="popup">
    <div class="content-ct">
      <h2>What's New?</h2>
      <div v-for="(item, index) in items" :key="index" class="news-ct">
        <div class="news-header">
          <span class="title">{{ item.news_title }}</span>
          <span class="date">{{ item.news_date }}</span>
        </div>
        <div class="body-msg" v-html="item.news_message"></div>
      </div>
    </div>
    <div class="btns">
      <div></div>
      <button class="action-btn confirm" @click="_confirm">
        Close
      </button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '../BaseDialog.vue'

export default {
  name: 'WhatsNewDialog',

  components: { BaseDialog },

  data: () => ({
    items: [],
    okButton: 'Submit', // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Cancel', // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.items = []
      if (opts.items) {
        this.items = JSON.parse(JSON.stringify(opts.items))
        this.items.map((el) => {
          el.news_date = new Date(el.news_date).toLocaleDateString('en-GB')
        })
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped>
:deep(a) {
  text-decoration: underline !important;
  color: #06c !important;
}
.body-msg {
  padding: 0 12px;
  display: grid;
}
.news-ct {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 35px;
}
.news-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-bottom: 5px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #0a4bb7;
}
.news-header .title {
  font-weight: 500;
}
.news-header .date {
  font-weight: 500;
  font-size: 14px;
  color: #b1b1b1;
}
h2 {
  color: #b1b1b1;
}
.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.label-class {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.action-btn {
  padding: 0.5em 1em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.confirm {
  background-color: #282f54;
  color: #fff !important;
}
.content-ct {
  width: 650px;
  height: 550px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
</style>
