<template>
  <div class="header">
    <div class="title">
      <p>{{ title }}</p>
    </div>

    <div class="action-area">
      <template v-if="userInfo">
        <div
          v-if="view_mode.active"
          id="view-as"
          class="dropdown"
          v-bind:class="{
            hightlight: view_mode.selected.key !== null,
          }"
        >
          <i
            class="material-icons dropbtn"
            v-on:click="expandUserMenu('view_modes')"
            @mouseover="showMore('textViewAs')"
            @mouseout="hideMore('textViewAs')"
            >visibility</i
          >
          <span class="tip" style="right: -27px; top: 40px" v-if="textViewAs">{{
            view_mode.selected.key !== null
              ? "Current View: " + view_mode.selected.name
              : "Change View"
          }}</span>
          <div id="view_modes" class="dropdown-content">
            <div
              class="view_mode-line"
              v-for="(item, index) in view_mode.modes"
              :key="index"
              v-bind:class="{
                hightlight: item.key === view_mode.selected.key,
              }"
            >
              <a href="javascript:;" @click="changeView(item)">{{
                item.name
              }}</a>
            </div>
            <div class="view_mode-line">
              <a
                href="javascript:;"
                @click="changeView({ key: null, name: null })"
                style="justify-content: center"
                >Reset</a
              >
            </div>
          </div>
        </div>

        <!-- feature disabled for now
        <div
          id="notification"
          class="dropdown"
          v-bind:class="{
            'x-show': notifications.active,
            'x-hidden': !notifications.active,
            hightlight: notifications.highlighted,
          }"
        >
          <i
            class="material-icons dropbtn"
            v-on:click="expandUserMenu('maintenance')"
            @mouseover="showMore('textMaintenance')"
            @mouseout="hideMore('textMaintenance')"
            >notification_important</i
          >
          <span class="tip" style="right: -27px;top: 40px;" v-if="textMaintenance">Notifications</span>
          <div id="maintenance" class="dropdown-content">
            <div
              class="notification-line"
              v-for="(item, index) in notifications.data"
              :key="index"
              v-bind:class="{
                expired: item.expired,
                hightlight: item.highlighted,
              }"
            >
              <a v-bind:href="item.notification_url" target="_blank" v-if="item.notification_url">
                {{ item.description }}
              </a>
              <span v-if="!item.notification_url">{{ item.description }}</span>
            </div>
          </div>
        </div> -->
        <router-link to="/support">
          <span
            class="parent-ct"
            @mouseover="showMore('textSuport')"
            @mouseout="hideMore('textSuport')"
          >
            <i class="material-icons">chat</i>
            <span class="tip" style="right: -27px" v-if="textSuport"
              >Support</span
            >
          </span>
        </router-link>
        <div class="feedback-btn" @click="openFeedback()">
          <span
            class="parent-ct"
            @mouseover="showMore('textFeedback')"
            @mouseout="hideMore('textFeedback')"
          >
            <i class="material-icons">thumb_up</i>
            <span class="tip" v-if="textFeedback" style="right: -31px"
              >Feedback</span
            >
          </span>
        </div>
        <router-link to="/catalog/dashboard">
          <span
            class="parent-ct"
            @mouseover="showMore('textTerms')"
            @mouseout="hideMore('textTerms')"
          >
            <i class="material-icons">account_balance</i>
            <span class="tip" v-if="textTerms" style="right: -77px"
              >Terms and Conditions</span
            >
          </span>
        </router-link>
        <router-link to="/help">
          <span
            class="parent-ct"
            @mouseover="showMore('textHelp')"
            @mouseout="hideMore('textHelp')"
          >
            <i class="material-icons">help</i>
            <span class="tip" v-if="textHelp" style="right: -13px">Help</span>
          </span>
        </router-link>
        <router-link to="/profile">
          <span
            class="parent-ct"
            @mouseover="showMore('textProfile')"
            @mouseout="hideMore('textProfile')"
          >
            <i class="material-icons">people</i>
            <span class="tip" v-if="textProfile" style="right: -13px"
              >Profile</span
            >
          </span>
        </router-link>
        <div
          class="feedback-btn"
          v-if="is_multiple"
          @click="changeCompany()"
          title="Change Company"
        >
          <span
            class="parent-ct"
            @mouseover="showMore('textFeedback')"
            @mouseout="hideMore('textFeedback')"
          >
            <i class="material-icons">swap_horiz</i>
            <span class="tip" v-if="textFeedback" style="right: -31px"
              >Feedback</span
            >
          </span>
        </div>
        <div class="dropdown">
          <button v-on:click="expandUserMenu('myDropdown')" class="dropbtn">
            {{ userInfo.logo }}
          </button>
          <div id="myDropdown" class="dropdown-content">
            <a class="double-size" @click="goToProfile()">
              <span class="logo-menu">{{ userInfo.logo }}</span>
              <span>
                <strong>{{ userInfo.userName }}</strong>
                <br />
                {{ userInfo.email }}
              </span>
            </a>
            <router-link to="/profile">
              <i class="material-icons">people</i>My Profile
            </router-link>
            <router-link to="/support">
              <i class="material-icons">chat</i> Support
            </router-link>
            <a href="javascript:;" v-on:click="openFeedback()">
              <i class="material-icons">thumb_up</i> Feedback
            </a>
            <router-link to="/catalog/dashboard">
              <i class="material-icons">account_balance</i> Terms and Conditions
            </router-link>
            <router-link to="/help">
              <i class="material-icons">help</i> Help
            </router-link>
            <router-link to="/admin" v-if="is_ih_admin">
              <i class="material-icons">settings</i> Admin
            </router-link>
            <router-link to="/manage" v-if="is_da_admin">
              <i class="material-icons">settings</i> Administration
            </router-link>
            <a href="javascript:;" v-on:click="logout()" class="logout">
              <i class="material-icons">exit_to_app</i> Logout
            </a>
          </div>
        </div>
      </template>

      <router-link class="logo" to="/home">
        <span>
          <img
            src="../assets/images/logo.jpg"
            alt="lloyds logo"
            style="width: 93px; height: 38px"
          />
        </span>
      </router-link>
    </div>
    <UserFeedbackDialog ref="userFeedbackDialog"></UserFeedbackDialog>
  </div>
</template>

<script>
import HttpService from "@/services/http.service";
import AuthService from "@/services/auth.service";
import UserFeedbackDialog from "@/components/dialogs/components/UserFeedbackDialog.vue";
import { user } from "@/auth/auth0-plugin";
import CryptService from "@/services/crypt.service";

export default {
  name: "Header",
  components: {
    UserFeedbackDialog,
  },
  data: function () {
    return {
      is_ih_admin: false,
      is_da_admin: false,
      withSearch: false,
      learnMore: false,
      textSuport: false,
      textViewAs: false,
      textFeedback: false,
      textTerms: false,
      textHelp: false,
      textProfile: false,
      textTraining: false,
      textMaintenance: false,
      userInfo: { userName: null, logo: "" },
      is_multiple: false,
      notifications: {
        active: false,
      },
      view_mode: {
        modes: [],
        selected: {
          key: null,
          name: null,
        },
        active: false,
      },
      title: "",
      searchTerm: "",
      settings: {},
      dec: {},
    };
  },
  mounted: async function () {
    await this.bindData();
    const { sym, key } = window.hubCfg;
    CryptService.setSym(sym);
    const cfg = CryptService.decrypt(key, true);
    const auth_token = localStorage.getItem(cfg.crypt.header_token);
    const auth_data = CryptService.decrypt(auth_token, true);
    // console.log('auth_data', auth_data)
    const auth_user = await user.value;
    this.is_multiple = auth_data.multiple;
    let u = auth_user;
    const as = new AuthService();
    const dh = as.decodeHeader();
    this.view_mode.active = dh.is_lloyds;
    const nameArr = u.name.split(" ");
    const logo =
      nameArr[0].substring(0, 1) +
      (nameArr[1] ? nameArr[1].substring(0, 1) : "");
    this.userInfo = {
      logo: logo,
      email: u.email,
      userName: u.name,
    };
  },
  watch: {
    async $route() {
      if (this.$route.meta.title === "product-param") {
        this.title = "";
        HttpService.get(`/insight/getAppName/${this.$route.params.app}`).then(
          (title) => {
            if (title && title.length > 1) {
              this.title = title;
            } else {
              this.title = this.$route.params.app;
            }
          }
        );
      } else {
        this.title = this.$route.meta.title;
      }
      this.withSearch = window.location.pathname === "/home";
    },
  },
  created: async function () {
    if (this.$route.meta.title === "product-param") {
      this.title = "";
      HttpService.get(`/insight/getAppName/${this.$route.params.app}`).then(
        (title) => {
          if (title && title.length > 1) {
            this.title = title;
          } else {
            this.title = this.$route.params.app;
          }
        }
      );
    } else {
      this.title = this.$route.meta.title;
    }
    this.withSearch = window.location.pathname === "/home";
    await this.bindData();
    // sessionStorage.removeItem('view_mode')
    let searchValue = null;
    try {
      searchValue = JSON.parse(sessionStorage.getItem("view_mode"));
    } catch (e) {
      // cannot parse
    }
    if (searchValue) {
      this.view_mode.selected = searchValue;
    }
  },
  methods: {
    changeCompany() {
      // console.log("this.userInfo, ", this.userInfo);
      const email_key = CryptService.crypt(this.userInfo.email);
      this.$router.push(`/choose-account/${email_key}`)
    },
    changeView(item) {
      this.view_mode.selected = item;
      delete this.view_mode.selected["description"];
      delete this.view_mode.selected["id"];
      delete this.view_mode.selected["is_imported"];
      sessionStorage.setItem(
        "view_mode",
        JSON.stringify(this.view_mode.selected)
      );
      this.$emit("changeView", this.view_mode.selected);
    },
    async openFeedback() {
      const res = await this.$refs.userFeedbackDialog.show({});
      if (res) {
        if (res.rating) {
          res.url = window.location.href;
          await HttpService.post("/user-feedback/save", res);
        }
      }
    },
    goToProfile() {
      this.$router.push({
        name: "profile",
      });
    },
    async bindData() {
      // check for system notifications
      const data = await HttpService.get(`/common/notifications`);
      const now = new Date().getTime();
      this.view_mode.modes = data.modes;
      data.notifications.map((d) => {
        (d.expiration_date = new Date(d.expiration_date)),
          (d.highlight_until = new Date(d.highlight_until));

        d.expired = now > d.expiration_date.getTime();
        d.highlighted = now < d.highlight_until.getTime();

        if (d.expired) {
          d.highlighted = false;
        }
      });
      this.notifications = {
        active: data.notifications.length > 0,
        highlighted:
          data.notifications.filter((x) => x.highlighted === true).length > 0 ||
          false,
        data: data.notifications,
      };
      this.settings = data.settings;
      const as = new AuthService();
      const dec = as.decodeHeader();
      if (!dec) {
        await as.doLogout();
      }
      // console.log(dec, "decoded");
      const { is_ih_admin, is_da_admin } = dec;
      this.is_ih_admin = is_ih_admin;
      this.is_da_admin = is_da_admin;
      // console.log(dec, "decoded", this.is_ih_admin, this.is_da_admin);
      // disable delegated admin access
      // this.is_da_admin = false;
    },
    trySearch: function () {
      const debounceDuration = 300;
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.$emit("onSearch", this.searchTerm);
      }, debounceDuration);
    },
    showMore(key) {
      setTimeout(() => {
        this[key] = true;
      }, 250);
    },
    hideMore(key) {
      setTimeout(() => {
        this[key] = false;
      }, 250);
    },
    expandUserMenu: function (id) {
      document.getElementById(id).classList.toggle("show");
    },
    logout: async function () {
      const as = new AuthService();
      await as.doLogout();
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: flex;
  padding-top: 4px;
}

/*----------------------------------------------------------------------------------------------------
    HEADER
----------------------------------------------------------------------------------------------------*/
div#maintenance {
  padding: 0;
  width: 300px;
  right: -132px;
  font-size: 14px;
  line-height: 21px;
  color: #444;
}
#notification.hightlight,
#view-as.hightlight {
  color: rgba(40, 47, 84, 1) !important;
}
.view_mode-line {
  border-bottom: 1px solid #eaeaea;
  color: #000;
  padding: 4px;
}
.view_mode-line a {
  width: 100%;
}
.view_mode-line.hightlight {
  color: rgba(40, 47, 84, 1) !important;
  font-weight: bold;
}
.notification-line {
  border-bottom: 1px solid #eaeaea;
  color: #000;
}
.notification-line.expired {
  color: #ccc;
  display: none;
}
i.material-icons.dropbtn {
  background: transparent;
  color: inherit;
  font-size: 24px;
  margin: 0;
  padding: 0;
  border-radius: 0;
  line-height: 38px;
  margin-right: 10px;
}

i.material-icons.dropbtn:hover,
i.material-icons.dropbtn:focus {
  background-color: transparent;
  color: rgb(0, 33, 164);
}
.header {
  background-color: white;
  -webkit-box-shadow: 0 1px 5px rgba(57, 63, 72, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(57, 63, 72, 0.2);
  box-shadow: 0 1px 5px rgba(57, 63, 72, 0.2);
  letter-spacing: 1px;
  line-height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (min-width: 800px) {
  .header {
    font-size: 150% !important;
  }
}
@media screen and (max-width: 800px) {
  .header {
    font-size: 2.5vw !important;
  }
}
@media screen and (max-width: 700px) {
  .header {
    font-size: 12px !important;
  }
}
.action-area {
  color: rgb(161, 161, 162);
  font-size: 85%;
  padding-right: 18px;
  display: flex;
}

.action-area a:hover {
  color: rgb(0, 33, 164);
}

.logo {
  margin-left: auto;
  cursor: pointer;
  margin-left: 18px;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.separtor {
  background-color: rgb(142, 140, 138);
  height: 48px;
  letter-spacing: -14px;
  margin-right: 10px;
  margin-left: 10px;
  color: transparent;
}
.feedback-btn {
  padding: 6px;
  padding-right: 20px;
  cursor: pointer;
}
.dropbtn {
  background-color: rgba(40, 47, 84, 1);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border: none;
  cursor: pointer;
  border-radius: 22px;
  padding: 0px;
  line-height: 2;
  min-width: 39px;
  text-align: center;
  height: 38px;
  vertical-align: middle;
  margin-bottom: 16px !important;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: rgba(40, 47, 84, 1);
  color: rgb(255, 252, 178);
}

.logout {
  border-top: 1px solid rgba(57, 63, 72, 0.1);
}

.logo-menu {
  padding: 14px;
  background-color: rgba(40, 47, 84, 0.75);
  border-radius: 37px;
  font-weight: bold;
  color: #fff;
  margin-right: 14px;
}

.dropdown a:hover {
  background-color: rgb(250, 250, 252);
  color: rgb(0, 0, 0);
}

.double-size {
  display: flex !important;
  flex-direction: row;
  box-shadow: 0px 1px 0px rgba(204, 204, 204, 0.4);
  align-items: center;
  cursor: pointer;
}

@media (max-width: 420px) {
  .logo {
    display: none;
    width: 1px;
    padding-left: 0;
  }
  .action-area {
    padding-top: 5px;
  }
}
</style>

<style scoped>
.header-action {
  font-size: 100%;
}
.badge {
  margin-left: 14px;
  background-color: white;
  padding: 0;
  font-size: 12px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
span.parent-ct {
  position: relative;
}
span.tip {
  position: absolute;
  top: 24px;
  right: -22px;
  background-color: rgb(10, 75, 183);
  padding: 9px;
  height: 30px;
  line-height: 11px;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(57, 63, 72, 0.75);
  width: max-content;
}
.action-area {
  font-size: 65%;
}

.action-area a {
  padding: 6px;
  padding-right: 20px;
}

.badge:hover {
  -webkit-box-shadow: 1px 1px 1px rgba(57, 63, 72, 0.2);
  -moz-box-shadow: 1px 1px 1px rgba(57, 63, 72, 0.2);
  box-shadow: 1px 1px 1px rgba(57, 63, 72, 0.2);
}

.bullet {
  margin-right: 4px;
  display: inline-block;
  width: 4px;
  height: 17px;
}

.title {
  height: 100%;
  padding-left: 24px;
  display: flex;
  min-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
