import AuthService from "@/services/auth.service";

export default class AdminPermissionsService {
  getAllPermissions() {
    const as = new AuthService();
    const dh = as.decodeHeader();
    dh.ih_admin_permissions["_reference_data"] = this.isReferenceGroup();
    dh.ih_admin_permissions["_access"] = this.isAccessGroup();
    dh.ih_admin_permissions["_products"] = this.isProductGroup();
    dh.ih_admin_permissions["_notifications"] = this.isNotificationsGroup();
    // console.log(dh.ih_admin_permissions)
    return dh.ih_admin_permissions;
  }

  getPermission(key) {
    let permission = null;
    const as = new AuthService();
    const dh = as.decodeHeader();
    if (dh.ih_admin_permissions) {
      permission = dh.ih_admin_permissions[key];
    }
    return permission;
  }

  canView(key) {
    return this.getPermission(key) === "read" || this.canWrite(key);
  }

  canWrite(key) {
    return this.getPermission(key) === "full";
  }

  isReferenceGroup() {
    let hasRefData = false;
    const x = [
      "datafeed_groups",
      "classifications",
      "app_tags",
      "security_names",
      "company_types",
      "pdf_report_types",
      "team",
      "class_of_business",
      "report_category",
      "report_period",
    ];
    x.map((k) => {
      if (!hasRefData) {
        hasRefData = this.canView(k);
      }
    });
    return hasRefData;
  }

  isAccessGroup() {
    let hasAccessData = false;
    const x = [
      "companies",
      "users",
      "domains",
      "administrators",
      "access_requests",
      "integration",
      "devolved_admin_requests",
      "user_attr_requests",
    ];
    x.map((k) => {
      if (!hasAccessData) {
        hasAccessData = this.canView(k);
      }
    });
    return hasAccessData;
  }

  isProductGroup() {
    let hasAccessData = false;
    const x = [
      "apps",
      "datafeed",
      "about",
      "stories",
      "pdf_docs",
      "public_products",
      "help_page",
      "request_form"
    ];
    x.map((k) => {
      if (!hasAccessData) {
        hasAccessData = this.canView(k);
      }
    });
    return hasAccessData;
  }

  isNotificationsGroup() {
    let hasAccessData = false;
    const x = ["notifications", "news"];
    x.map((k) => {
      if (!hasAccessData) {
        hasAccessData = this.canView(k);
      }
    });
    return hasAccessData;
  }
}
