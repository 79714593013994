import axios from 'axios'
import CryptService from './crypt.service'

axios.interceptors.response.use(
  function(config) {
    return config
  },
  function(error) {
    return Promise.reject(error.response)
  }
)

var HttpService = (function() {
  var _tk = null

  var getHeader = function() {
    const { sym, key } = window.hubCfg
    CryptService.setSym(sym)
    const cfg = CryptService.decrypt(key, true)
    var header_key = cfg.crypt.header_token

    var appHeaders = {}
    appHeaders[header_key] = localStorage.getItem(header_key) || null

    if (!appHeaders[header_key]) {
      // delete appHeaders[key];
    }
    return appHeaders
  }

  function _baseUrl() {
    return '/api'
  }

  async function _getPublic(url) {
    return (await axios.get(_baseUrl() + '/public' + url)).data
  }

  async function _postPublic(url, data) {
    return (await axios.post(_baseUrl() + '/public' + url, data)).data
  }

  async function _post(url, data) {
    try {
      const _headers = getHeader()
      _headers['Authorization'] = `Bearer ${_tk}`
      return (
        await axios.post(_baseUrl() + url, data, {
          headers: _headers,
        })
      ).data
    } catch (e) {
      if (e.data.code === '23505') {
        alert(e.data.detail)
        return
      }
      if (e.status === 422) {
        if (e.data.code === 'not_valid') {
          alert(e.data.message.join('\n'))
        }
      }
      throw e
    }
  }

  async function _delete(url) {
    const _headers = getHeader()
    _headers['Authorization'] = `Bearer ${_tk}`
    return await axios.delete(_baseUrl() + url, {
      headers: _headers,
    })
  }

  async function _upload(url, data, header) {
    header['Authorization'] = `Bearer ${_tk}`
    return (
      await axios.post(_baseUrl() + url, data, {
        headers: Object.assign(header, getHeader()),
      })
    ).data
  }

  async function _refreshToken() {
    try {
      const _headers = getHeader()
      _headers['Authorization'] = `Bearer ${_tk}`
      const { data } = await axios.get(_baseUrl() + '/auth/refresh-token', {
        headers: _headers,
      })
      const { sym, key } = window.hubCfg
      CryptService.setSym(sym)
      const cfg = CryptService.decrypt(key, true)
      localStorage.setItem(cfg.crypt.header_token, data)
      // const x =  CryptService.decrypt(data)
      // console.log(x)
    } catch (e) {
      throw e
    }
  }

  async function _get(url) {
    try {
      const _headers = getHeader()
      _headers['Authorization'] = `Bearer ${_tk}`
      return (
        await axios.get(_baseUrl() + url, {
          headers: _headers,
        })
      ).data
    } catch (e) {
      throw e
    }
  }

  async function _download(url) {
    const _headers = getHeader()
    _headers['Authorization'] = `Bearer ${_tk}`
    return await axios({ url: _baseUrl() + url, responseType: 'blob', method: 'GET', headers: _headers })
  }

  async function _downloadPost(url, data) {
    const _headers = getHeader()
    _headers['Authorization'] = `Bearer ${_tk}`
    return await axios({
      url: _baseUrl() + url,
      responseType: 'blob',
      method: 'POST',
      headers: _headers,
      data,
    })
  }

  async function _setToken(tk) {
    _tk = tk
  }

  function getToken() {
    return _tk
  }

  return {
    refreshToken: _refreshToken,
    getPublic: _getPublic,
    postPublic: _postPublic,
    setToken: _setToken,
    post: _post,
    get: _get,
    delete: _delete,
    upload: _upload,
    download: _download,
    downloadPost: _downloadPost,
    baseUrl: _baseUrl,
    getHeader,
    getToken,
  }
})()

export default HttpService
