import HttpService from "@/services/http.service";
import { getInstance } from "../auth/authWrapper";
import CryptService from "@/services/crypt.service";
import router from "../router";
import axios from "axios";
import { watch } from "vue";
import {
  getAccessToken,
  isLoading,
  isAuthenticated,
  logout,
  user,
} from "@/auth/auth0-plugin";

export default class AuthService {
  /**
   * app logout
   *
   * @returns
   */
  async doLogout() {
    // debugger
    const cfg = window.full_cfg;
    localStorage.removeItem(cfg.crypt.header_token);
    sessionStorage.removeItem("view_mode");
    sessionStorage.removeItem("access_token");
    const fn = async () => {
      if (isAuthenticated.value) {
        sessionStorage.setItem("from_logout", "true");
        // await logout({logoutParams:{returnTo: window.location.origin + "/cacat"}});
        logout({
          returnTo: window.location.origin,
        });
      }
    };
    if (!isLoading.value) {
      return fn();
    }
    watch(
      () => isLoading,
      async (loading, old) => {
        // console.log("watch: ", loading, old);
        if (loading === false) {
          return fn();
        }
      }
    );
  }

  async doLogin() {
    try {
      const { sym, key } = window.hubCfg;
      CryptService.setSym(sym);
      const cfg = CryptService.decrypt(key, true);
      window.cfg = cfg;
      window.full_cfg = cfg;
      const auth_user = await user.value;
      // console.log("user", user);
      const accessToken = await getAccessToken();
      HttpService.setToken(accessToken);
      if (auth_user["https://identity.lloyds.com/upn"]) {
        auth_user.email =
          auth_user["https://identity.lloyds.com/upn"].toLowerCase();
      }
      window.usr = auth_user;
      if(window.dec_data) {
        auth_user.company_id = window.dec_data.cid || null;
      }
      /**
       * @todo send email with cryptoJs to the backend.
       */
      const data = await HttpService.post("/auth/login", {
        d: CryptService.crypt({
          user: auth_user,
        }),
      });
     // console.log('data', data)
      // debugger
      sessionStorage.setItem("access_token", data.access_token);
      localStorage.setItem(
        cfg.crypt.header_token,
        data[cfg.crypt.header_token]
      );
    } catch (e) {
      console.log(e);
      let mode = "";
      if (e && e.data && e.data.error) {
        if (e.data.error.split("::")[1] === "subscription_expired") {
          mode = "/expired";
        }
        if (e.data.error.split("::")[1] === "login_disabled") {
          mode = "/login-disabled";
        }
        if (e.data.error.split("::")[1] === "ext_account_not_supported") {
          mode = "/no-ext-accounts";
        }
        // router.push('/access-denied' + mode)
      }
      // console.log("mode", mode);
      const err_len = e.data.error.split("::").length;
      if (err_len === 3) {
        router.push("/choose-account/" + e.data.error.split("::")[2]);
      } else {
        router.push("/access-denied" + mode);
      }
      // window.location.reload()
    }
  }

  decodeHeader() {
    const { sym, key } = window.hubCfg;
    CryptService.setSym(sym);
    const cfg = CryptService.decrypt(key, true);
    const hh = localStorage.getItem(cfg.crypt.header_token);
    if (!hh) {
      // this.doLogout()
      return null;
    }
    const dec_data = CryptService.decrypt(
      localStorage.getItem(cfg.crypt.header_token),
      true
    );
    window.dec_data = dec_data;
    return dec_data;
  }

  async createDataSessionJWT() {
    try {
      const { sym, key } = window.hubCfg;
      CryptService.setSym(sym);
      const cfg = CryptService.decrypt(key, true);
      const at = sessionStorage.getItem("access_token") || null;

      if (!at) {
        return "access_denied";
      }
      const { data } = await axios.get(
        `${cfg.qlik.url}/${cfg.qlik.proxy}/qps/user`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );
      return data;
    } catch (e) {
      console.log(e);
      throw e || "qlik_unavailable";
    }
  }
}
