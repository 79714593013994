<template>
  <div class="box sideTop alignCenterMiddle" @click="expandBar()">
    <p v-if="!isSideBarCollapsed">Insights Hub</p>
    <i v-if="isSideBarCollapsed" class="material-icons md-24">menu</i>
  </div>
</template>

<script>
export default {
  name: "SideTop",
  data: function() {
    return {};
  },
  props: {
    isSideBarCollapsed: Boolean
  },

  methods: {
    expandBar: function() {
      if (!this.isSideBarCollapsed) {
        this.$emit("eventCollapseSideBar", true);
      } else {
        this.$emit("eventCollapseSideBar", false);
      }
    }
  }
};
</script>

<style scoped>
.sideTop {
  background-color: rgb(40, 47, 84);
  color: white;
  text-align: center;
  font-size: 130%;
  font-weight: 500;
  letter-spacing: 2px;
  cursor: pointer;
}
</style>
