/**
 * @author Florin Ancuta
 * @email [achebv@gmail.com]
 * @create date 2019-05-30 09:16:14
 * @modify date 2019-05-30 09:16:14
 * @desc [description]
 */
var MenuService = (function() {
  var bottomItems = []
  var topItems = []

  var getNewMenu = async function(
    is_ih_admin = false,
    is_da_admin = false,
    with_pdf_reports = true,
    ihBadge = 0,
    daBadge = 0,
    repBadge = 0
  ) {
    const ihAdminMenu = {
      href: '/admin',
      title: 'Admin',
      icon: 'settings',
      badge: ihBadge,
    }
    const devolvedAdminMenu = {
      href: '/manage',
      title: 'Administration',
      icon: 'settings',
      badge: daBadge,
    }
    bottomItems = []
    topItems = [
      {
        href: '/home',
        title: 'Home',
        icon: 'home',
      },
      {
        href: '/product',
        title: 'Insights',
        icon: 'apps',
      },
      {
        href: '/datafeeds',
        title: 'Data Feeds',
        icon: 'table_chart',
      },
    ]
    if (with_pdf_reports) {
      topItems.push({
        href: '/reports',
        title: 'Reports',
        icon: 'description',
        badge: repBadge,
      })
    }
    if (is_da_admin) {
      bottomItems.push(devolvedAdminMenu)
    }
    if (is_ih_admin) {
      bottomItems.push(ihAdminMenu)
    }
    return [
      ...[
        ...topItems,
        {
          href: '/stories',
          title: 'Stories',
          icon: 'perm_media',
        },
        {
          href: '/whats-new',
          title: `What's New?`,
          icon: 'fiber_new',
        },
        {
          title: 'Privacy',
          mode: 'bottom-link',
          href: 'https://www.lloyds.com/help/privacy'
        },
      ],
      ...bottomItems,
      {
        title: 'Privacy',
        mode: 'bottom-link',
        href: 'https://www.lloyds.com/help/privacy'
      },
    ]
  }

  // public methods
  return {
    buildMenu: getNewMenu,
  }
})()

export default MenuService
