import CryptService from "@/services/crypt.service";
import axios from "axios";
import { createApp } from "vue";
import { createGtm } from "vue-gtm";
import router from "./router";
import Shell from "./shell.vue";

axios.get(`/api/public/config`).then(async (d1) => {
  // console.log(d1);
  window.hubCfg = d1.data;

  const app = createApp(Shell);
  // Vue.prototype.appCfg = d1.data;
  app.config.globalProperties.appCfg = d1.data;

  const { sym, key, slt } = d1.data;
  CryptService.setSlt(slt);
  CryptService.setSym(sym);
  const cfg = CryptService.decrypt(key, true);
  window.full_cfg = cfg;
  window.cfg = cfg;

  if (cfg.gaTag !== "") {
    app.use(
      createGtm({
        id: cfg.gaTag,
        defer: false,
        compatibility: false,
        nonce: "2726c7f26c",
        enabled: true,
        debug: !cfg.production,
        loadScript: true,
        vueRouter: router,
        ignoredViews: ["callback"],
        trackOnNextTick: false
      })
    );
  }

  app.config.globalProperties.$config = function () {
    const { sym, key } = window.hubCfg;
    CryptService.setSym(sym);
    return CryptService.decrypt(key, true);
  };

  app.use(router);
  app.config.globalProperties.$axios = axios;

  // filters
  app.config.globalProperties.$filters = {
    formatDate(value) {
      if (!value) {
        return "N/A";
      }
      const date = new Date(value);
      // console.log(date, value);
      return date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    formatDateOnly(value) {
      if (!value) {
        return "N/A";
      }
      const date = new Date(value);
      return date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    },
    formatNumber(value) {
      if (!value) {
        return "N/A";
      }
      return value.toLocaleString(
        undefined, // leave undefined to use the visitor's browser
        // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 2 }
      );
    },
  };

  app.mount("#app");
});
