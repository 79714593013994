<template>
  <BaseDialog ref="popup">
    <div class="content-ct">
      <template v-if="step === 1">
        <div class="row-flex-start mb-20">
          <h3></h3>
          <i class="material-icons action-icon" @click="_cancel">close</i>
        </div>
        <h3>How likely are you to recommend the Insights Hub to a colleague?</h3>
        <div class="row-flex mt-20" @mouseleave="onHoverExit()">
          <span
            class="score-btn"
            v-for="(score, key) in scores"
            :key="key"
            v-bind:class="{
              low: colored[score] && score < 7,
              medium: colored[score] && score > 6 && score < 9,
              high: colored[score] && score > 8,
            }"
            @mouseenter="onHoverEnter(score)"
            @click="onScoreClick(score)"
          >
            {{ score }}
          </span>
        </div>
        <div class="row-flex">
          <div class="low-score"></div>
          <div class="medium-score"></div>
          <div class="high-score"></div>
        </div>
      </template>
      <template v-else>
        <div class="row-flex-start mb-20">
          <div class="row-flex small" @click="step = 1">
            <span
              class="score-btn"
              v-for="(score, key) in scores"
              :key="key"
              v-bind:class="{
                low: colored[score] && score < 7,
                medium: colored[score] && score > 6 && score < 9,
                high: colored[score] && score > 8,
              }"
            >
              {{ score }}
            </span>
          </div>
          <i class="material-icons action-icon" @click="_cancel">close</i>
        </div>
        <h3>What could we do to make this site better?</h3>
        <textarea
          id="text-zone"
          v-model="comment"
          rows="20"
          maxlength="2000"
          class="field-with_hint"
          style="height:100%; font-size: 16px;"
        ></textarea>
        <span class="field-hint">
          <span v-if="selectedScore < 9">Please tell us what was wrong?</span>
          ({{ 2000 - comment.length }} characters left)
        </span>
      </template>
    </div>
    <div class="btns">
      <div class="editor-line form-row"></div>
      <button
        :disabled="!selectedScore || (selectedScore < 9 && comment.length < 1)"
        class="action-btn confirm"
        @click="_confirm"
      >
        Submit
      </button>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '../BaseDialog.vue'

export default {
  name: 'UserFeedbackDialog',

  components: { BaseDialog },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    okButton: 'Submit', // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: 'Cancel', // text for cancel button
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
    scores: [],
    colored: {},
    selectedScore: null,
    step: 1,
    comment: '',
  }),

  methods: {
    onScoreClick(score) {
      this.step = 2
      this.selectedScore = score
      setTimeout(() => {
        document.getElementById('text-zone').focus()
      })
    },
    onHoverEnter(score) {
      this.colored = {}
      let aux = Array.from(Array(score + 1).keys())
      aux.shift()
      for (let s of aux) {
        this.colored[s] = true
      }
    },
    onHoverExit() {
      this.colored = {}
      if (this.selectedScore) {
        let aux = Array.from(Array(this.selectedScore + 1).keys())
        aux.shift()
        for (let s of aux) {
          this.colored[s] = true
        }
      }
    },
    show(opts = {}) {
      this.step = 1
      this.colored = {}
      this.selectedScore = null
      this.comment = ''
      if (opts.okButton) {
        this.okButton = opts.okButton
      }
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      this.scores = Array.from(Array(11).keys())
      this.scores.shift()
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      const obj = {
        rating: this.selectedScore,
        comment: this.comment,
      }
      this.$refs.popup.close()
      this.resolvePromise(obj)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(null)
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
}
</script>

<style scoped>
.small {
  width: 50% !important;
  /* transform: scale(0.6) */
}
.low {
  background: #fd6464 !important;
  color: white !important;
}
.medium {
  background: #fcd21a !important;
  color: white !important;
}
.high {
  background: #16b311 !important;
  color: white !important;
}
.low-score {
  width: 59%;
  background: #fd6464;
  height: 7px;
  border-radius: 30px;
  margin-top: 20px;
}
.medium-score {
  width: 17%;
  background: #fcd21a;
  height: 7px;
  margin-top: 20px;
  border-radius: 30px;
}
.high-score {
  width: 16%;
  background: #16b311;
  height: 7px;
  margin-top: 20px;
  border-radius: 30px;
}
.action-icon {
  cursor: pointer;
}
.row-flex-start {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.row-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.small .score-btn {
  width: 25px;
  height: 25px;
}
.score-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  border: 1px solid #d6d1d1;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}
.checkbox-label {
  margin-left: 10px;
  margin-bottom: 14px;
}
.editor-line.checkbox,
.checkbox {
  display: flex;
  align-items: center;
}

.btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.label-class {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.action-btn {
  padding: 0.5em 1em;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.confirm {
  background-color: #282f54;
  color: #fff !important;
}

.cancel {
  background-color: #fd6464;
  color: #fff !important;
}
.content-ct {
  width: 700px;
  height: 280px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.editor-line {
  padding: 6px 18px;
}

.editor-line.sb {
  justify-content: space-between;
}

.editor-line label {
  white-space: nowrap;
  min-width: 130px;
}
.editor-line.form-row {
  display: flex;
  align-items: center;
}
</style>
