<template>
  <div v-if="auth0 && auth0.isLoading.value" class="page-layout">
    Loading...
  </div>

  <router-view v-else />
</template>

<script>
import { useAuth0 } from "@/auth/auth0-plugin";
import { onMounted } from "vue";
import "./assets/css/insightshub.css";
import { watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "App",
  setup() {
    const auth0 = useAuth0();
    const $route = useRoute();

    onMounted(async () => {
      if (!auth0) {
        return;
      }
      await auth0.createClient();
      await auth0.handleCallback();
    });

    watch(
      () => $route,
      (to, from) => {
        console.log(to, from, "route changed");
      }
    );

    return {
      auth0,
    };
  },
};
</script>
