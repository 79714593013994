import  createAuth0Client  from "@auth0/auth0-spa-js";
import { reactive, provide } from "vue";

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  auth0Client: null,
  popupOpen: false,
  error: null,
});

export const getInstance = () => state

export const useAuth0 = async ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin + "/callback2",
  ...options
}) => {
  // console.log('redirectUri', redirectUri)
  if (state.auth0Client) return state;

  state.auth0Client = await createAuth0Client({
    ...options,
    client_id: options.clientId,
    redirect_uri: redirectUri,
    useRefreshTokens: true,
    cacheLocation: "localstorage",
  });

  try {
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      const { appState } = await state.auth0Client.handleRedirectCallback();
      state.error = null;
      onRedirectCallback(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    state.isAuthenticated = await state.auth0Client.isAuthenticated();
    state.user = await state.auth0Client.getUser();
    state.loading = false;
  }

  state.loginWithRedirect = async (o) => {
    return state.auth0Client.loginWithRedirect(o);
  };

  state.handleRedirectCallback = async (o) => {
    state.loading = true;
    try {
      await state.auth0Client.handleRedirectCallback();
      state.user = await state.auth0Client.getUser();
      state.isAuthenticated = true;
      state.error = null;
    } catch (e) {
      state.error = e;
    } finally {
      state.loading = false;
    }
  };

  state.logout = async (o) => {
    sessionStorage.setItem("logout_2", "from authWrapper function");
    sessionStorage.setItem("logout", true);
    sessionStorage.removeItem("view_mode");
    sessionStorage.removeItem("access_token");
    return state.auth0Client.logout(o);
  };

  state.getTokenSilently = async (o) => {
    return ''; // state.auth0Client.getTokenSilently(o);
  }

  return state;
};

export const provideAuth = async (options) => {
  const auth0 = await useAuth0(options);
  // provide('auth0', auth0)
  return auth0;
};
