<template>
  <div>
    <div
      class="ih-banner"
      v-if="withBanner"
      v-bind:style="{
        color: bannerData.banner_text_color,
        background: bannerData.banner_bg_color,
      }"
    >
      <i class="material-icons close-banner-btn" @click="onCloseBanner()"
        >close</i
      >
      <p class="banner-title">{{ bannerData.banner_title }}</p>
      <p class="banner-content">{{ bannerData.banner_content }}</p>
    </div>
    <div
      id="mainGrid"
      :class="{ sideBarCollapsed: sideBarCollapsed, wrapper: true }"
    >
      <Header
        :title="this.title"
        @onSearch="processSearch"
        @changeView="changeMode"
      ></Header>
      <SideTop
        :is-side-bar-collapsed="sideBarCollapsed"
        @eventCollapseSideBar="collapseSideBar"
      />
      <SideBar
        :is-side-bar-collapsed="sideBarCollapsed"
        @eventCollapseSideBar="collapseSideBar"
      />
      <div class="box content">
        <router-view
          :config="searchTerm"
          :view_mode="selected_mode"
        ></router-view>
      </div>
    </div>
    <UserFeedbackDialog ref="userFeedbackDialog"></UserFeedbackDialog>
    <WhatsNewDialog ref="whatsNewDialog"></WhatsNewDialog>
    <InfoDialog ref="infoDialog"></InfoDialog>
  </div>
</template>
<script>
import HttpService from "@/services/http.service";

import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import SideTop from "@/components/SideTop.vue";
import InfoDialog from "@/components/dialogs/components/InfoDialog.vue";
import UserFeedbackDialog from "@/components/dialogs/components/UserFeedbackDialog.vue";
import WhatsNewDialog from "@/components/dialogs/components/WhatsNewDialog.vue";

export default {
  name: "AppLayout",
  components: {
    Header,
    SideBar,
    SideTop,
    UserFeedbackDialog,
    WhatsNewDialog,
    InfoDialog,
  },
  data: () => ({
    selected_mode: null,
    searchTerm: "",
    sideBarCollapsed: false,
    title: "",
    bannerData: {},
    withBanner: false,
  }),
  async mounted() {
    const cfg = this.$config();
    if (cfg.createdHours !== null && +cfg.createdHours < 24) {
      await this.$refs.infoDialog.show({
        message: `As your user has been approved less than 24 hours ago, you might still not have full access to the site.`,
      });
    }
    this.openWhatsNew();
    this.openUserFeedback();
    this.getBannerData();
  },
  methods: {
    onCloseBanner() {
      this.withBanner = false;
      sessionStorage.setItem("appBannerSeen", "seen");
    },
    async getBannerData() {
      const res = await HttpService.getPublic(`/landing/bannerData`);
      this.bannerData = res;
      this.withBanner = false;
      if (this.bannerData.banner_active && this.bannerData.banner_display_app) {
        const ssVar = sessionStorage.getItem("appBannerSeen");
        if (!ssVar || ssVar !== "seen") {
          this.withBanner = true;
        }
      } else {
        sessionStorage.removeItem("appBannerSeen");
      }
    },
    isToday(someDate) {
      const today = new Date();
      const compareDate = new Date(someDate);
      return (
        compareDate.getDate() === today.getDate() &&
        compareDate.getMonth() === today.getMonth() &&
        compareDate.getFullYear() === today.getFullYear()
      );
    },
    async openWhatsNew() {
      const { news } = await HttpService.get("/user-news/getUnseenNews");
      if (news && news.length > 0) {
        await this.$refs.whatsNewDialog.show({ items: news });
      }
    },
    async openUserFeedback() {
      const { answered, minutes } = await HttpService.get(
        "/user-feedback/checkFeedback"
      );
      const miliseconds = +minutes * 60000;
      const npsAppearanceDay = localStorage.getItem("npsAppearanceDay");
      if (!answered && (!npsAppearanceDay || !this.isToday(npsAppearanceDay))) {
        localStorage.setItem("npsAppearanceDay", new Date());
        setTimeout(async () => {
          if (this.$refs.userFeedbackDialog) {
            const res = await this.$refs.userFeedbackDialog.show({});
            if (res) {
              if (res.rating) {
                res.url = window.location.href;
                await HttpService.post("/user-feedback/save", res);
              }
            }
          }
        }, miliseconds);
      }
    },
    collapseSideBar(value) {
      this.sideBarCollapsed = value;
    },
    processSearch(str) {
      this.searchTerm = str;
    },
    changeMode(mode) {
      this.selected_mode = mode;
    },
  },
  watch: {
    $route(to, from) {
      // console.log('here...', to, from)
      if (window.cfg && window.cfg.use_log_navigation) {
        HttpService.post("/auth/log-navigation", {
          url: to.fullPath,
        });
      }
      if (window.hubMeta) {
        this.title = window.hubMeta.title;
      }
    },
  },
};
</script>
<style scoped>
.ih-banner {
  min-height: 100px;
  display: flex;
  padding: 16px 50px 20px;
  flex-direction: column;
  position: relative;
}
.close-banner-btn {
  position: absolute;
  right: 18px;
  top: 10px;
  cursor: pointer;
}
.ih-banner p {
  display: block;
}
.banner-title {
  font-weight: 500;
  font-size: 18px;
}
</style>
