<template>
  <div>
    <SidebarMenu
      :menu="menu"
      :collapsed="isSideBarCollapsed"
      :theme="selectedTheme"
      :show-one-child="false"
      @collapse="onCollapse"
    ></SidebarMenu>
  </div>
</template>

<script>
import SidebarMenu from '@/components/menu/SidebarMenu.vue'
import MenuService from '@/services/menu.service'
import AuthService from '@/services/auth.service'
import HttpService from '@/services/http.service'
import AdminPermissionsService from '@/services/admin-permissions.service'

export default {
  name: 'SideBar',
  components: {
    SidebarMenu,
  },
  props: {
    isSideBarCollapsed: Boolean,
  },
  data() {
    return {
      menu: [],
      collapsed: true,
      themes: ['', 'lloyds-theme'],
      selectedTheme: 'lloyds-theme',
    }
  },
  watch: {
    async $route() {
      const urlPathSplit = this.$route.path.split('/')
      const urlPath = urlPathSplit[urlPathSplit.length - 1]
      if (urlPath && (urlPath === 'reports' || urlPath === 'report')) {
        this.buildMenu()
      }
    },
  },
  created() {
    this.buildMenu()
  },
  methods: {
    async buildMenu() {
      const as = new AuthService()
      let { is_ih_admin, is_da_admin, with_pdf_reports } = as.decodeHeader()
      let ihBadge = 0
      let daBadge = 0
      let repBadge = 0
      const res = await HttpService.get('/getBadges')

      if (is_ih_admin || is_da_admin) {
        const aps = new AdminPermissionsService()
        if (is_ih_admin && aps.canWrite('devolved_admin_requests')) {
          ihBadge += +res.ihBadge
        }
        if (is_da_admin) {
          daBadge += +res.daBadge
        }
      }
      repBadge = +res.reportsBadge

      const appMenu = await MenuService.buildMenu(
        is_ih_admin,
        is_da_admin,
        with_pdf_reports,
        ihBadge,
        daBadge,
        repBadge
      )
      this.menu = appMenu
      this.$emit('eventCollapseSideBar', this.collapsed)
    },
    onCollapse(collapsed) {
      this.collapsed = collapsed
      this.$emit('eventCollapseSideBar', this.collapsed)
    },
  },
}
</script>
